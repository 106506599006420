import React from 'react'
import { ConnectionRejectedError, useWallet, UseWalletProvider } from 'use-wallet'
import {
  BscConnector,
  UserRejectedRequestError,
} from '@binance-chain/bsc-connector'

import nft from "./contracts/CharmonMinter.json";
import erc20 from "./contracts/CHMON.json"
import market from "./contracts/CharmonMarket.json"
 
const Web3 = require('web3');

const erc20Address = '0x90e78BF1FF50A68bb24455adF48623691619Fe0e';
const marketAddress = '0xac79576115927a3E326879CA1aD238eE1cAFaC88';
const nftAddress = '0xEB96Bf77A70d2452E4B951A3ae193aD0f05edF46';

var web3 = new Web3()
const fromWei=(value,to)=>{
  return web3.utils.fromWei(value,to)
}

const toWei=(value,from)=>{
  return web3.utils.toWei(value,from)
}

var web3;
var tokenID;
var tokenPrice;
var tokenOwner;
var isNFTApproved = false;
var isErc20Approved = false;
var marketContract;

async function buyNTF(userAccount, wallet) {
    console.log(wallet); 
    marketContract = await new web3.eth.Contract(market.abi, marketAddress)
    const _price=await marketContract.methods.price().call()
    tokenPrice = fromWei(String(_price), 'ether')
    try {
        
          tokenID=await marketContract.methods.tokenId().call()
          tokenOwner=await marketContract.methods.tokenOwner().call()        
     console.log(tokenID);
     console.log(tokenOwner);
     console.log(tokenPrice);   
      /*console.log(marketAddress);
      console.log(market.abi);
      console.log(marketContract);*/
      
      await marketContract.methods.buyNFT( tokenID,tokenPrice).send({from:userAccount})   
    }
    catch (e) {
      console.log(e.message)
    
    }
}

async function approveNTF(userAccount, wallet) {
    try {
        
      const nftContract = await new web3.eth.Contract(nft.abi, nftAddress)
        var approval = await nftContract.methods.setApprovalForAll( marketAddress,true).send({from:userAccount})
        console.log(approval);
        //setIsNFTApproved(true)
    }
    catch (e) {
      console.log(e.message)
      //setLoading(false)
    }
}

async function approveErc20(userAccount, wallet) {
    try {
    marketContract = await new web3.eth.Contract(market.abi, marketAddress)
    const _price=await marketContract.methods.price().call()
    var tokenPrice = fromWei(String(_price), 'ether')
      const erc20Contract = await new web3.eth.Contract(erc20.abi, erc20Address)
        await erc20Contract.methods.approve( marketAddress,toWei(tokenPrice,"ether")).send({from:userAccount})      
    }
    catch (e) {
      console.log(e.message)
      //setLoading(false)
    }
}

async function InitContracts(accs) {
    console.log(accs);
    marketContract = await new web3.eth.Contract(market.abi, marketAddress)
    var _price = await marketContract.methods.price().call()  
    isErc20Approved=await marketContract.methods.isAllowedERC20(accs[0],_price).call()
      
    tokenOwner=await marketContract.methods.tokenOwner().call()  
    isNFTApproved=await marketContract.methods.isApprovedNFT(tokenOwner).call() 
    
    console.log(tokenOwner);
}

function App() {
  const wallet = useWallet()
  const blockNumber = wallet.getBlockNumber()

  //const web3Context = useWeb3(`https://data-seed-prebsc-1-s1.binance.org:8545`);
  if(wallet.status == 'connected') {
      /*web3 = new Web3(
        new Web3.providers.HttpProvider('https://data-seed-prebsc-1-s1.binance.org:8545')
      );*/
      
      web3 = new Web3(wallet.ethereum);
    
      /*web3.eth.getBlock('latest').then(console.log)   
      console.log(web3.eth); 
      web3.eth.getAccounts().then(console.log)*/
      wallet.ethereum.on('networkChanged', async function (netId) {
        console.log(netId);
      });
      window.ethereum.on('accountsChanged', async function (accounts) {
        console.log(accounts);
      }); 
      window.ethereum.on('chainChanged', async function (chain) {
        console.log(chain);
      });
    console.log(wallet);
    wallet.ethereum
      .request({ method: 'eth_accounts' })
      .then(function(accs) {
            InitContracts(accs);       
      });  
           
  }
  return (
    <>
      <h1>Wallet</h1>
      {wallet.status === 'connected' ? (
        <div>
            <p>NFT ID: {tokenID}</p>
            <p>Token Price:{tokenPrice}</p>
            <p>Token Owner:{tokenOwner}</p>
            <p>is NFT Approved : {String(isNFTApproved)} </p>
            <p>is Amount Approved : {String(isErc20Approved)} </p>
          <div>Account: {wallet.account}</div>
          <div>Balance: {wallet.balance}</div>
          <button onClick={ () => approveErc20(wallet.account, wallet) }>ApproveERC20</button>
          <button onClick={ () => approveNTF(wallet.account, wallet) }>Approve NTF</button>
          <button onClick={ () => buyNTF(wallet.account, wallet) }>Buy NTF</button>
          <button onClick={() => wallet.reset()}>disconnect</button>
        </div>
      ) : (
        <div>
          Connect:
          <button onClick={() => wallet.connect()}>MetaMask</button>
          <button onClick={() => wallet.connect('bsc')}>Binance</button>
        </div>
      )}
    </>
  )
}

// Wrap everything in <UseWalletProvider />
export default () => (
  <UseWalletProvider
    chainId={97}
    connectors={{
      bsc: {
        web3ReactConnector() {
          return new BscConnector({ supportedChainIds: [56, 97] })
        },
        handleActivationError(err) {
          if (err instanceof UserRejectedRequestError) {
            return new ConnectionRejectedError()
          }
        },
      },
    }}
  >
    <App />
  </UseWalletProvider>
)